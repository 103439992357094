
import { Options, Vue } from 'vue-class-component'
import IconButton from '../01-atoms/Icon-button.vue'
import Tag from '../01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import router from '@/router'

class Props {
  item?: object;
}

@Options({
  name: 'Inquiries-table-row',
  components: {
    Icon,
    Tag,
    IconButton
  }
})

export default class TableRowInquiry extends Vue.with(Props) {
  clickInquiry (id: string) {
    router.push({ name: 'Inquiry', params: { id: id } })
  }
}
