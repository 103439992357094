<template>
  <div class="quick-upload ta-center">
    <h2 class="display-3 mt-0">Quick Upload</h2>
    <p class="grey-dark mb-l">Upload your invoices here to get a refund</p>
    <IconButton :to="{name: 'New inquiry'}" icon="plus-circle" background="blue-dark-10" class="mb-m">
      {{ $t('topbar.new-request') }}
    </IconButton>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

@Options({
  name: 'Quick-upload',
  components: {
    IconButton
  }
})
export default class QuickUpload extends Vue {
}
</script>
<style lang="scss" scoped>
.quick-upload {
  border: 4px dashed $blue-dark-10;
  padding: 20px 10px;
}
</style>
