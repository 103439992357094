
import { Options, Vue } from 'vue-class-component'
import Flickity from '../flickity.vue'
import Button from '@/01-atoms/Button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, SliderServiceKey } from '@/services/serviceTypes'

@Options({
  name: 'Slider',
  components: {
    Flickity,
    Button
  }
})

export default class SliderService extends Vue {
  overlayOffsetNumber = 200
  flickityOptions = {
    pageDots: false,
    wrapAround: true
  }

  predefinedColors = {
    blue: 'd9edf4',
    yellow: 'fde8a2',
    pink: 'f8d4d6',
    green: 'd1eccf'
  }

  sliderService = inject(SliderServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.sliderService?.loadSlides()
        .then(() => this.mountSlides())
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  mountSlides () {
    setTimeout(() => {
      const slide: any = this.$refs.flickity
      slide.init()
    }, 50)
  }

  sliderBackground (slide: object) {
    let sliderClass = 'background:'
    sliderClass += `${this.overlayStretch(slide['overlayColor'])} no-repeat 0% 0%/calc(100% - ${this.overlayOffset * 2.5}px),`
    sliderClass += `${this.overlay(slide['overlayColor'])} no-repeat calc(100% - ${this.overlayOffset}px) 0%/auto ${this.overlayOffset + 18}px,`
    sliderClass += `url(${slide['backgroundImageUrl']}) no-repeat 100% 100%/auto 100%;`
    return sliderClass
  }

  get overlayOffset () {
    const mediaQuery = window.matchMedia('(max-width: 720px)')
    mediaQuery.addEventListener('change', this.calculateOffset)
    this.calculateOffset(mediaQuery)
    return this.overlayOffsetNumber
  }

  calculateOffset (mediaQuery: any) {
    if (mediaQuery.matches) {
      this.overlayOffsetNumber = 100
    } else {
      this.overlayOffsetNumber = 200
    }
  }

  overlayStretch (colorName: string) {
    const colorHex = this.getPredefinedColor(colorName)
    return `url("data:image/svg+xml,%3Csvg height='1' viewBox='0 0 1 1' width='1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23${colorHex}' fill-rule='evenodd'/%3E%3C/svg%3E")`
  }

  overlay (colorName: string) {
    const colorHex = this.getPredefinedColor(colorName)
    return `url("data:image/svg+xml,%3Csvg height='218' viewBox='0 0 491 218' width='491' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23${colorHex}' d='m265.815028 217.092969c1.508246-21.395683 7.418274-37.304583 17.730083-47.726699 15.467713-15.633174 72.985666-40.564283 101.793821-74.0783476 28.808155-33.5140648 29.016273-42.3375108 48.613713-60.3092728 13.06496-11.9811748 31.976679-23.6097349 56.735156-34.88568043h-490.36908511v216.99999983z' fill-rule='evenodd'/%3E%3C/svg%3E")`
  }

  getPredefinedColor (colorName: string) {
    return this.predefinedColors[colorName]
  }
}

