
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

@Options({
  name: 'Quick-upload',
  components: {
    IconButton
  }
})
export default class QuickUpload extends Vue {
}
