
import { Options, Vue } from 'vue-class-component'
import InputSelect from '@/01-atoms/Input-select.vue'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import eventHub from '@/event-hub'
import Icon from '@/01-atoms/Icon.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, InquiryServiceKey, MemberServiceKey } from '@/services/serviceTypes'
import { PatchInquiry } from '@/services/inquiries/InquiryService'
import { postInquiry } from '@/api/inquiries/inquiries-api'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import { getFileDownload, postUploadsFile } from '@/api/uploads/files-api'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

interface Inquiry {
  categoryId: string;
  title: string;
  files: [
    string
  ];
  bankAccount: string;
}

@Options({
  name: 'Quick-inquiry',
  components: {
    LoadingDots,
    InputSelect,
    Button,
    IconButton,
    QrCode,
    Icon
  }
})

export default class QuickInquiry extends Vue {
  uploadUrl = ''
  qrCodeUrl = 'https://lightbulb.lu/'
  newCategoryId = ''
  inquirySubmitted = false

  newInquiryBankAccountId = ''

  isLoading = false
  isImageLoading = false

  errors = false

  newInquiryUploads = [] as Array<Upload>

  beforeMount () {
    this.loadCategories()
    this.loadMemberBankAccount()
  }

  currentMemberService = inject(MemberServiceKey)
  loadMemberBankAccount () {
    this.currentMemberService?.loadCurrentMember()
        .then(() => {
          if (this.currentMemberService?.currentMember.bankAccounts.length) {
            this.newInquiryBankAccountId = this.currentMemberService?.currentMember.bankAccounts[0].id
          }
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  inquiriesService = inject(InquiryServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loadCategories () {
    this.inquiriesService?.loadCategories()
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  newUpload = {
    title: '',
    file: '',
    id: '',
    fileType: 'default'
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    getFileDownload(file.url)
      .then((response) => {
        const image = {
          'title': response.data.fileName,
          'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
          'fileType': type,
          'id': file.id
        }
        this.newInquiryUploads.push(image)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  createFile (ev: Event) {
    if (ev.target) {
      this.newUpload = {
        title: ev.target['files'][0].name,
        file: URL.createObjectURL(ev.target['files'][0]),
        id: '',
        fileType: 'default'
      }

      const file = new FormData()
      file.append('title', this.newUpload.title)
      file.append('file', ev.target['files'][0])
      file.append('fileType', 'default')

      this.uploadFile(file)
    }
  }

  uploadFile (file: any) {
    this.isImageLoading = true
    postUploadsFile(file)
      .then((response) => {
        this.newUpload['id'] = response.data.id
        this.addNewInquiryUpload(this.newUpload)
        this.isImageLoading = false

        this.newUpload = {
          title: '',
          file: '',
          id: '',
          fileType: 'default'
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  addNewInquiryUpload (upload: Upload) {
    this.newInquiryUploads.push(upload)
  }

  removeNewInquiryUpload (id: string) {
    this.newInquiryUploads = this.newInquiryUploads.filter((item) => {
      return id !== item.id
    })
  }

  submit (ev: Event) {
    ev.preventDefault()
    if (this.formIsValid()) {
      this.createInquiry()
    } else {
      this.errors = true
    }
  }

  formIsValid () {
    return this.newCategoryId !== ''
  }

  createInquiry () {
    const newInquiry = {
      title: '',
      bankAccount: this.newInquiryBankAccountId,
      categoryId: this.newCategoryId,
      files: this.createFilesIdList()
    }
    this.sendInquiry(newInquiry)
  }

  createFilesIdList () {
    const filesIds: Array<string> = []
    this.newInquiryUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    return filesIds
  }

  sendInquiry (inquiry: PatchInquiry) {
    this.isLoading = true
    postInquiry(inquiry)
      .then((response) => {
        const id = response.data.id
        this.updateInquiries(id)
      })
  }

  updateInquiries (id: string) {
    Promise.all([
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true)
    ]).then(() => {
      this.inquirySubmitted = true
      this.isLoading = false
      this.resetQuickUpload()
    })
  }

  forwardToInquiry (id: string) {
    this.$router.push({ name: 'Inquiry', params: { id: id } })
  }

  resetQuickUpload () {
    this.resetUploadInput()
    this.resetCategory()
    this.resetUploads()
  }

  resetUploads () {
    this.newInquiryUploads = [] as Array<Upload>
  }

  resetUploadInput () {
    this.uploadUrl = ''
    const upload: any = this.$refs['upload-input']
    upload.value = ''
  }

  resetCategory () {
    eventHub.$emit('reset-select')
  }

  nextInquiry () {
    this.inquirySubmitted = false
  }
}
