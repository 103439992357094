<template>
  <tr class="table-row" @click="clickInquiry(item.id)">
    <td class="table__item-preview">
      <Icon class="mr-m xm-up" background="blue-dark" :icon="item.category.icon" />
      <div>
        <router-link :to="{ name: 'Inquiry', params: { id: item.id } }" class="fs-16 fw-700 table__item-title">
          {{ item.title }}
        </router-link>
        <span class="d-b grey-dark md-up">{{ item.id }}</span>
      </div>
    </td>
    <td class="fs-14 grey-dark xl-up">{{ formatDate(item.uploadedAt) }}</td>
    <td class="fs-14 grey-dark xl-up">{{ item.uploadedBy }}</td>
    <td class="ta-center">
      <Tag :variant="item.status" responsive />
    </td>
    <td class="ta-center">
      <Tag :variant="item.treatmentStatus" responsive />
    </td>
  </tr>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '../01-atoms/Icon-button.vue'
import Tag from '../01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import router from '@/router'

class Props {
  item?: object;
}

@Options({
  name: 'Inquiries-table-row',
  components: {
    Icon,
    Tag,
    IconButton
  }
})

export default class TableRowInquiry extends Vue.with(Props) {
  clickInquiry (id: string) {
    router.push({ name: 'Inquiry', params: { id: id } })
  }
}
</script>
