<template>
  <div class="content-box">
    <div class="content-box__inner">
      <h2 class="fs-20 fw-700 mt-0 tt-uppercase" v-if="$slots.title">
        <slot name="title">#title</slot>
      </h2>
      <div class="fs-16 grey-dark">
        <slot>template</slot>
      </div>
    </div>
    <div class="content-box__footer grey-dark fw-700" v-if="$slots.footer">
      <slot name="footer">#footer</slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

/**
 * Text centered white box with title, content and footer
 *
 * @description {string?} template#title
 * @description {HTML} template
 * @description {HTML?} template#footer
 */

@Options({
  name: 'Content-box'
})

export default class ContentBox extends Vue { }
</script>
<style lang="scss" scoped>
.content-box {
  border-radius: 10px;
  background-color: $grey-dark-10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.content-box__inner {
  padding: 20px 10px;
}

.content-box__footer {
  background-color: rgba(0,0,0, 0.1);
  border-radius: 0 0 10px 10px;
  padding: 11px 10px;

  p {
    margin: 0;
  }
}
</style>
