
import { Options, Vue } from 'vue-class-component'

/**
 * Text centered white box with title, content and footer
 *
 * @description {string?} template#title
 * @description {HTML} template
 * @description {HTML?} template#footer
 */

@Options({
  name: 'Content-box'
})

export default class ContentBox extends Vue { }
