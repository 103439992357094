<template>
  <Tabs class="mb-l" :active-tab-id="activeTabId" :items="tabsItems" @switch-tab="switchTab" />
  <Table
      :activeTabId="activeTabId"
      @load-older-entries="loadOlderEntries"
      :isLoading="isLoading()"
      :entries="sortedInquiries(activeTabId)"
  >
    <TableHeader v-if="sortedInquiries(activeTabId)[4]?.length !== 0" label="global.latest" :items="tableHeaders" />
    <TableRowInquiry v-for="item in sortedInquiries(activeTabId)[4]" v-bind:key="item.id" :item="item" />
    <TableHeader v-if="sortedInquiries(activeTabId)[3]?.length !== 0" label="global.last-week" :items="tableHeaders" />
    <TableRowInquiry v-for="item in sortedInquiries(activeTabId)[3]" v-bind:key="item.id" :item="item" />
    <TableHeader v-if="sortedInquiries(activeTabId)[2]?.length !== 0" label="global.2-weeks-ago" :items="tableHeaders" />
    <TableRowInquiry v-for="item in sortedInquiries(activeTabId)[2]" v-bind:key="item.id" :item="item" />
    <TableHeader v-if="sortedInquiries(activeTabId)[1]?.length !== 0" label="global.month-ago" :items="tableHeaders" />
    <TableRowInquiry v-for="item in sortedInquiries(activeTabId)[1]" v-bind:key="item.id" :item="item" />
    <TableHeader v-if="sortedInquiries(activeTabId)[0]?.length !== 0" label="global.year-ago" :items="tableHeaders" />
    <TableRowInquiry v-for="item in sortedInquiries(activeTabId)[0]" v-bind:key="item.id" :item="item" />
  </Table>
  <div style="display: none;">
    {{ $t('global.recent') }}
    {{ $t('global.approved') }}
    {{ $t('global.rejected') }}
    {{ $t('global.pending') }}
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Tabs from '../02-molecules/Tabs.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, InquiryServiceKey } from '@/services/serviceTypes'
import { useI18n } from 'vue-i18n'
import getTimeAgo from '@/helpers/get-time-ago'
import { Inquiry } from '@/services/inquiries/InquiryService'
import Table from '@/02-molecules/Table.vue'
import TableRowInquiry from '@/02-molecules/Table-row-inquiry.vue'
import TableHeader from '@/02-molecules/Table-header.vue'

@Options({
  components: {
    TableHeader,
    TableRowInquiry,
    Table,
    Tabs
  }
})

export default class InquiriesPanel extends Vue {
  tabsItems = {
    recent: {
      title: 'Recent',
      id: 'recent',
      currentPage: 1,
      isLoading: true
    },
    reimbursed: {
      title: 'Approved',
      id: 'reimbursed',
      currentPage: 1,
      isLoading: true
    },
    rejected: {
      title: 'Rejected',
      id: 'rejected',
      currentPage: 1,
      isLoading: true
    },
    pending: {
      title: 'Pending',
      id: 'pending',
      currentPage: 1,
      isLoading: true
    }
  }

  tableHeaders = [
    {
      title: 'table.date',
      responsive: true
    },
    {
      title: 'table.uploadedBy',
      responsive: true
    },
    {
      title: 'table.inquiry',
      responsive: false
    },
    {
      title: 'table.treatment',
      responsive: false
    }
  ]

  activeTabId = this.tabsItems[Object.keys(this.tabsItems)[0]].id
  itemPerPage = 20

  inquiriesService = inject(InquiryServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.setTranslations()
    this.loadAllInquiries()
    this.loadCategories()
  }

  sortedInquiries (status: string) {
    return this.sortInquiries(this.inquiriesService?.inquiries[status])
  }

  sortInquiries (inquiries: any) {
    const newArray = [[], [], [], [], []]
    inquiries.forEach((inquiry: Inquiry) => {
      const index = getTimeAgo(inquiry.uploadedAt)!.index
      newArray[index].push(inquiry)
    })
    return newArray
  }

  loadCategories () {
    this.inquiriesService?.loadCategories()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  loadAllInquiries () {
    this.loadEntries('recent', 1, this.itemPerPage)
    this.loadEntries('reimbursed', 1, this.itemPerPage)
    this.loadEntries('rejected', 1, this.itemPerPage)
    this.loadEntries('pending', 1, this.itemPerPage)
  }

  setTranslations () {
    const { t } = useI18n()
    this.tabsItems['recent'].title = t('global.recent')
    this.tabsItems['reimbursed'].title = t('global.approved')
    this.tabsItems['rejected'].title = t('global.rejected')
    this.tabsItems['pending'].title = t('global.pending')
  }

  loadEntries (type = this.activeTabId, currentPage = 1, itemPerPage = this.itemPerPage) {
    this.inquiriesService?.loadInquiries(type, currentPage, itemPerPage, false, this.isLoadingCallback)
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  isLoadingCallback (status: string) {
    this.tabsItems[status].isLoading = false
  }

  isLoading () {
    for (const tabsItemsKey in this.tabsItems) {
      if (this.tabsItems[tabsItemsKey].isLoading) {
        return true
      }
    }
    return false
  }

  loadOlderEntries () {
    this.tabsItems[this.activeTabId].isLoading = true
    const currentPage = this.setCurrentPage()
    this.inquiriesService?.loadOlderInquiries(this.activeTabId, currentPage, this.itemPerPage, this.isLoadingCallback)
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  setCurrentPage () {
    this.tabsItems[this.activeTabId].currentPage = this.tabsItems[this.activeTabId].currentPage + 1
    return this.tabsItems[this.activeTabId].currentPage
  }

  switchTab (id: string) {
    this.activeTabId = id
    this.loadEntries()
  }
}
</script>
