<template>
  <form :class="[isLoading && 'is-loading', errors && 'form has-errors']" class="quick-inquiry background--blue-dark-10 ta-center">
    <div v-if="!inquirySubmitted">
      <h2 class="display-2 quick-inquiry__title" style="display: inline-block;">{{ $t('quick-inquiry.title') }}</h2>
      <p>{{ $t('quick-inquiry.category-label') }}</p>
      <InputSelect
          class="mb-l quick-inquiry__select"
          background="white"
          v-model="newCategoryId"
          :options="inquiriesService.categories"
          :placeholder="$t('quick-inquiry.category')"
      />
      <div class="quick-inquiry__upload mb-s ta-center" v-for="item in newInquiryUploads" v-bind:key="item.id">
        <div class="quick-inquiry__upload__media">
          <img class="quick-inquiry__upload__image" :src="item.file || item.imageContent" alt="">
          <IconButton
              round
              icon="x-circle"
              background="transparent"
              @click="removeNewInquiryUpload(item.id)"
              class="quick-inquiry__upload__delete"
          />
        </div>
      </div>
      <div class="quick-inquiry__upload mb-s ta-center">
        <div class="quick-inquiry__upload__inner">
          <QrCode class="quick-inquiry__qr-code" type="default" @add-qr-file="addQrFile" />
          <p>{{ $t('quick-inquiry.qr-code-instructions') }}</p>
          <LoadingDots class="quick-inquiry__loading-dots" v-if="isImageLoading" />
          <label v-else class="quick-inquiry__upload__label" for="quick-inquiry">
            {{ $t('quick-inquiry.upload-button') }}
          </label>
          <input ref="upload-input" id="quick-inquiry" type="file" class="quick-inquiry__upload__input" @change="createFile">
        </div>
      </div>
      <div class="ta-center mb-l" style="display: inline-block">
        <Button @click="submit($event)" inverse >{{ $t('quick-inquiry.send-button') }}</Button>
      </div>
    </div>
    <div v-else>
      <h2 class="display-2 quick-inquiry__title mb-xl" style="display: inline-block;">Request submitted</h2>
      <Icon icon="check-circle" class="blue-dark quick-inquiry__confirmation-icon mb-xl"></Icon>
      <div class="ta-center mb-l" style="display: inline-block">
        <Button @click="nextInquiry" inverse >{{ $t('topbar.new-request') }}</Button>
      </div>
    </div>
  </form>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import InputSelect from '@/01-atoms/Input-select.vue'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import eventHub from '@/event-hub'
import Icon from '@/01-atoms/Icon.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, InquiryServiceKey, MemberServiceKey } from '@/services/serviceTypes'
import { PatchInquiry } from '@/services/inquiries/InquiryService'
import { postInquiry } from '@/api/inquiries/inquiries-api'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import { getFileDownload, postUploadsFile } from '@/api/uploads/files-api'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

interface Inquiry {
  categoryId: string;
  title: string;
  files: [
    string
  ];
  bankAccount: string;
}

@Options({
  name: 'Quick-inquiry',
  components: {
    LoadingDots,
    InputSelect,
    Button,
    IconButton,
    QrCode,
    Icon
  }
})

export default class QuickInquiry extends Vue {
  uploadUrl = ''
  qrCodeUrl = 'https://lightbulb.lu/'
  newCategoryId = ''
  inquirySubmitted = false

  newInquiryBankAccountId = ''

  isLoading = false
  isImageLoading = false

  errors = false

  newInquiryUploads = [] as Array<Upload>

  beforeMount () {
    this.loadCategories()
    this.loadMemberBankAccount()
  }

  currentMemberService = inject(MemberServiceKey)
  loadMemberBankAccount () {
    this.currentMemberService?.loadCurrentMember()
        .then(() => {
          if (this.currentMemberService?.currentMember.bankAccounts.length) {
            this.newInquiryBankAccountId = this.currentMemberService?.currentMember.bankAccounts[0].id
          }
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  inquiriesService = inject(InquiryServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loadCategories () {
    this.inquiriesService?.loadCategories()
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  newUpload = {
    title: '',
    file: '',
    id: '',
    fileType: 'default'
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    getFileDownload(file.url)
      .then((response) => {
        const image = {
          'title': response.data.fileName,
          'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
          'fileType': type,
          'id': file.id
        }
        this.newInquiryUploads.push(image)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  createFile (ev: Event) {
    if (ev.target) {
      this.newUpload = {
        title: ev.target['files'][0].name,
        file: URL.createObjectURL(ev.target['files'][0]),
        id: '',
        fileType: 'default'
      }

      const file = new FormData()
      file.append('title', this.newUpload.title)
      file.append('file', ev.target['files'][0])
      file.append('fileType', 'default')

      this.uploadFile(file)
    }
  }

  uploadFile (file: any) {
    this.isImageLoading = true
    postUploadsFile(file)
      .then((response) => {
        this.newUpload['id'] = response.data.id
        this.addNewInquiryUpload(this.newUpload)
        this.isImageLoading = false

        this.newUpload = {
          title: '',
          file: '',
          id: '',
          fileType: 'default'
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  addNewInquiryUpload (upload: Upload) {
    this.newInquiryUploads.push(upload)
  }

  removeNewInquiryUpload (id: string) {
    this.newInquiryUploads = this.newInquiryUploads.filter((item) => {
      return id !== item.id
    })
  }

  submit (ev: Event) {
    ev.preventDefault()
    if (this.formIsValid()) {
      this.createInquiry()
    } else {
      this.errors = true
    }
  }

  formIsValid () {
    return this.newCategoryId !== ''
  }

  createInquiry () {
    const newInquiry = {
      title: '',
      bankAccount: this.newInquiryBankAccountId,
      categoryId: this.newCategoryId,
      files: this.createFilesIdList()
    }
    this.sendInquiry(newInquiry)
  }

  createFilesIdList () {
    const filesIds: Array<string> = []
    this.newInquiryUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    return filesIds
  }

  sendInquiry (inquiry: PatchInquiry) {
    this.isLoading = true
    postInquiry(inquiry)
      .then((response) => {
        const id = response.data.id
        this.updateInquiries(id)
      })
  }

  updateInquiries (id: string) {
    Promise.all([
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true)
    ]).then(() => {
      this.inquirySubmitted = true
      this.isLoading = false
      this.resetQuickUpload()
    })
  }

  forwardToInquiry (id: string) {
    this.$router.push({ name: 'Inquiry', params: { id: id } })
  }

  resetQuickUpload () {
    this.resetUploadInput()
    this.resetCategory()
    this.resetUploads()
  }

  resetUploads () {
    this.newInquiryUploads = [] as Array<Upload>
  }

  resetUploadInput () {
    this.uploadUrl = ''
    const upload: any = this.$refs['upload-input']
    upload.value = ''
  }

  resetCategory () {
    eventHub.$emit('reset-select')
  }

  nextInquiry () {
    this.inquirySubmitted = false
  }
}
</script>
<style lang="scss" scoped>
.quick-inquiry {
  border-radius: 10px;
  padding: 0 13px;
}

.quick-inquiry__title {
  margin-top: 20px;
}

.quick-inquiry__upload {
  border: 4px dashed $blue-dark;
  border-radius: 10px;
  height: 260px;
  margin: 0 auto 20px;
}

.quick-inquiry__upload__inner {
  padding: 28px;
}

.quick-inquiry__qr-code {
  margin: 0 auto;
}

// Blue button white text
.quick-inquiry__upload__label {
  @include brandon-grotesque-bold;
  appearance: none;
  background: $blue-dark;
  border: none;
  border-radius: 30px;
  color: $white;
  display: inline-block;
  font-size: 14px;
  font-family: sans-serif;
  padding: 8px 23px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

.quick-inquiry__upload__input {
  position: absolute;
  opacity: 0;
  width: 0;
}

.quick-inquiry__upload__image {
  @include object-fit;
  border-radius: 10px;
}

.quick-inquiry__upload__delete {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.quick-inquiry__upload__media {
  height: 100%;
  position: relative;
}

.quick-inquiry__confirmation-icon {
  height: 85px;
  margin-right: auto;
  margin-left: auto;
  width: 85px;
}

.quick-inquiry__select {
  border: 1px solid transparent;
}

.form.has-errors {
  .quick-inquiry__select {
    border: 1px solid $red;
  }
}

.quick-inquiry__loading-dots {
  height: 32px;
}
</style>
